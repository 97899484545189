import { authActions, LoginCredentials, useAppDispatch } from '@app.raytd.com/store';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface UseLoginReturn {
  login: (data: LoginCredentials) => Promise<boolean>;
  loginWithToken: (token: string) => Promise<boolean>;
  isLoading: boolean;
  error: string;
}

export function useLogin(): UseLoginReturn {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLoginSuccess = useCallback(() => {
    const from = location.state?.from?.pathname || '/';
    const redirectTo = from === '/logout' ? '/' : from;
    navigate(redirectTo);
  }, [location.state?.from?.pathname, navigate]);

  const login = useCallback(
    async (data: LoginCredentials) => {
      try {
        setIsLoading(true);
        setError('');

        const response = await dispatch(authActions.login(data)).unwrap();
        console.debug('Login response', response);

        const user = await dispatch(authActions.getUser()).unwrap();
        console.debug('User response', user);

        //handleLoginSuccess();
        return true;
      } catch (err: any) {
        console.debug('Login error:', err);
        setError(err?.message ?? 'Login failed');
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const loginWithToken = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true);
        setError('');

        const user = await dispatch(authActions.loginWithToken(token)).unwrap();
        console.debug('Token login response', user);

        //handleLoginSuccess();
        return true;
      } catch (err: any) {
        console.error('Token login error:', err);
        setError(err?.message ?? 'Token login failed');
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return {
    login,
    loginWithToken,
    isLoading,
    error,
  };
}
